// AuthContext.js
import { createContext, useContext, useState } from 'react';
import Cookies from 'js-cookie';


const AuthContext = createContext();

export const AuthProvider = ({ children }) => {


  const [isLoggedIn, setIsLoggedIn] = useState(!!sessionStorage.getItem('jwt-token'));
  const [username, setUsername] = useState(sessionStorage.getItem('username') || '');
  const [newFormData, setNewFormData] = useState({});
  const [selectedUsername, setSelectedUsername] = useState('');
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('')

  const _login = (userdata) => {

    try{

    sessionStorage.setItem('jwt-token', userdata.token);
    setIsLoggedIn(true);

    sessionStorage.setItem('username', userdata.username);
    setUsername(userdata.username);

    if(userdata.isAdmin){

      Cookies.set('ad_data', true, { secure: true});

    }

  }catch(error){

    console.log(error);
  }

};

  const _logout = () => {
    sessionStorage.removeItem('jwt-token');
    sessionStorage.removeItem('username');
    setIsLoggedIn(false); 
    Cookies.remove('ad_data');
  };


  //dropdown content

  return (
    <AuthContext.Provider value={{ isLoggedIn, _login, _logout, username, newFormData, setNewFormData, selectedUsername, setSelectedUsername, error, setError, message, setMessage}}>

      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
