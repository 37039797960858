import requests from '../../requests';
import FormSubmitError from '../Success/FormSubmitError';
import FormSubmitSuccess from '../Success/FormSubmitSuccess';
import './style.css';
import DotLoader from '../DotLoader';
import React, { useState, useEffect } from 'react';

const PaperSubmissionForm = () => {

    const [formData, setFormData] = useState({
        paper_title: '',
        first_author: '',
        corresponding_author: '',
        third_author: '',
        fourth_author: '',
        institute_affiliation: '',
        abstract_word_count: '',
        keywords: '',
        abstract_pdf: null,
        main_word_count: '',
        no_of_figures: '',
        mainbody_pdf: null,
        full_word_count: '',
        full_paper_pdf: null,
        illustrations_number: '',
        illustrations_pdf: null,
    });

    const [success, setSuccess] = useState('');
    const [fail, setFail] = useState('');
    const [isDataExists, setIsDataExists] = useState(false);
    const [isFormLocked, setIsFormLocked] = useState(false);
    const [loader, setLoader] = useState(false);


    useEffect(() => {
        // Fetch existing data for the user when the component mounts
        const fetchData = async () => {
            try {
                const response = await requests.getPaper();
                const dataArray = response.data;

                if (dataArray.length > 0) {
                    const selectedData = await dataArray[0];
                    setFormData(selectedData);
                    setIsDataExists(true);

                    console.log(selectedData?.isLocked);

                    if (selectedData.isLocked) {
                        setIsFormLocked(true);
                    }
                }

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();

    }, [loader]);

    const handleChange = (e) => {

        if (!isFormLocked) {
            const { name, value, type, files } = e.target;

            if (type === 'file' && files.length > 0) {

                const selectedFile = files[0];

                // Check if the file is a PDF
                const isPDF = selectedFile.type === 'application/pdf';

                // Determine the size limit based on the field name
                let sizeLimit = 0; // default size limit
                if (name === 'abstract_pdf') {
                    sizeLimit = 4 * 1024 * 1024; // 4MB for abstract
                } else if (name === 'mainbody_pdf') {
                    sizeLimit = 6 * 1024 * 1024; // 6MB for main body
                } else if (name === 'full_paper_pdf') {
                    sizeLimit = 12 * 1024 * 1024; // 12MB for full paper
                } else if (name === 'illustrations_pdf') {
                    sizeLimit = 5 * 1024 * 1024; // 5MB for illustrations
                }

                // Check if the file size exceeds the limit
                if (selectedFile.size > sizeLimit) {
                    setFail(`File size for ${name} exceeds the limit`);

                    // Clear the selected file
                    e.target.value = null;
                    return;
                }

                // Check if the file is a PDF
                if (!isPDF) {
                    setFail(`Only PDF files are allowed for ${name}`);
                    // Clear the selected file
                    e.target.value = null;
                    return;
                }
            }


                setFormData((prevData) => ({
                    ...prevData,
                    [name]: type === 'file' ? files[0] : value,
                }));

                setFail('');

            }
        };

        const handleSave = async () => {

            setLoader(true);

            if (!isFormLocked) {
                if (isDataExists) {

                    try {
                        const response = await requests.updatePaper(formData);
                        setLoader(false);
                        setSuccess(response.data.message);

                        setTimeout(() => {

                            setSuccess('');

                        }, 4000)

                    } catch (error) {

                        setFail('Failed to update data. Please try again.');

                        setTimeout(() => {

                            setFail('');

                        }, 4000)
                    }

                } else {

                    try {

                        const response = await requests.paperUpload(formData);
                        setLoader(false);
                        if (response.status === 200) {

                            console.log(response.data);

                            setSuccess(response.data.message);

                            setTimeout(() => {

                                setSuccess('');

                            }, 4000)

                        } else {

                            console.log(response.data);

                            setFail(response.data.message);

                            setTimeout(() => {

                                setFail('');

                            }, 4000)
                        }

                    } catch (error) {
                        setFail('Failed to submit data. Please try again.');
                    }

                }

            }


        };

        const handleLock = async () => {

            try {

                const response = await requests.setLock();


                if (response.data.isLocked) {

                    setIsFormLocked(true);
                    setSuccess("This form is locked from your side. Contact Admin.")
                }

            } catch (error) {

                console.log(error);
            }


        };

        console.log('home re-rending')


        const inputClass = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500";

        return (
            <div className="container mx-auto mt-8">

                {/* Paper Details Table */}
                <table className="w-full border-collapse border border-gray-300 m-5">
                    <thead>
                        <tr className="bg-gray-200">
                            <th className="border border-gray-300 p-2">Paper Title</th>
                            <th className="border border-gray-300 p-2">First Author</th>
                            <th className="border border-gray-300 p-2">Corresponding Author</th>
                            <th className="border border-gray-300 p-2">Third Author</th>
                            <th className="border border-gray-300 p-2">Fourth Author</th>
                            <th className="border border-gray-300 p-2">Institute Affiliation</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="border border-gray-300 p-2">
                                <input
                                    type="text"
                                    name="paper_title"
                                    value={formData.paper_title}
                                    className={inputClass}
                                    placeholder='Paper Title'
                                    onChange={handleChange}
                                    disabled={isFormLocked}
                                    required
                                />
                            </td>

                            <td className="border border-gray-300 p-2">
                                <input
                                    type="text"
                                    name="first_author"
                                    value={formData.first_author}
                                    className={inputClass}
                                    placeholder='First Author'
                                    onChange={handleChange}
                                    disabled={isFormLocked}
                                    required
                                />
                            </td>
                            <td className="border border-gray-300 p-2">
                                <input
                                    type="text"
                                    name="corresponding_author"
                                    value={formData.corresponding_author}
                                    className={inputClass}
                                    placeholder='Corresponding Author'
                                    onChange={handleChange}
                                    disabled={isFormLocked}
                                />
                            </td>
                            <td className="border border-gray-300 p-2">
                                <input
                                    type="text"
                                    name="third_author"
                                    value={formData.third_author}
                                    className={inputClass}
                                    placeholder='Third Author'
                                    onChange={handleChange}
                                    disabled={isFormLocked}
                                />
                            </td>
                            <td className="border border-gray-300 p-2">
                                <input
                                    type="text"
                                    name="fourth_author"
                                    value={formData.fourth_author}
                                    className={inputClass}
                                    placeholder='Fourth Author'
                                    onChange={handleChange}
                                    disabled={isFormLocked}
                                />
                            </td>
                            <td className="border border-gray-300 p-2">
                                <input
                                    type="text"
                                    name="institute_affiliation"
                                    value={formData.institute_affiliation}
                                    className={inputClass}
                                    placeholder='Institute Affiliation'
                                    onChange={handleChange}
                                    disabled={isFormLocked}
                                />
                            </td>
                        </tr>

                    </tbody>

                </table>

                {/* Abstract Table */}
                <table className="w-full border-collapse border border-gray-300 m-5">
                    <thead>
                        <tr className="bg-gray-200">
                            <th className="border border-gray-300 p-2">Abstract Word Count</th>
                            <th className="border border-gray-300 p-2">Keywords</th>
                            <th className="border border-gray-300 p-2">Upload Abstract PDF</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="border border-gray-300 p-2">
                                <input
                                    type="number"
                                    name="abstract_word_count"
                                    value={formData.abstract_word_count}
                                    className={inputClass}
                                    placeholder='Abstract Word Count'
                                    onChange={handleChange}
                                    disabled={isFormLocked}
                                />
                            </td>
                            <td className="border border-gray-300 p-2">
                                <input
                                    type="text"
                                    name="keywords"
                                    value={formData.keywords}
                                    className={inputClass}
                                    placeholder='Keywords'
                                    onChange={handleChange}
                                    disabled={isFormLocked}
                                />
                            </td>
                            <td className="border border-gray-300 p-2">

                                <div className="flex align-middle">
                                    <input
                                        type="file"
                                        name="abstract_pdf"
                                        onChange={handleChange}
                                        disabled={isFormLocked}
                                    />
                                    <p className="text-sm text-red-600 mt-1">Max limit: 4MB</p>

                                </div>
                            </td>
                        </tr>

                    </tbody>
                </table>

                {/* Main Body Table */}
                <table className="w-full border-collapse border border-gray-300 m-5">
                    <thead>
                        <tr className="bg-gray-200">
                            <th className="border border-gray-300 p-2">Main Body Word Count</th>
                            <th className="border border-gray-300 p-2">No of Figures</th>
                            <th className="border border-gray-300 p-2">Upload Main Body PDF</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="border border-gray-300 p-2">
                                <input
                                    type="number"
                                    name="main_word_count"
                                    value={formData.main_word_count}
                                    className={inputClass}
                                    placeholder='Main Body Word Count'
                                    onChange={handleChange}
                                    disabled={isFormLocked}
                                />
                            </td>
                            <td className="border border-gray-300 p-2">
                                <input
                                    type="number"
                                    name="no_of_figures"
                                    value={formData.no_of_figures}
                                    className={inputClass}
                                    placeholder='No of Figures'
                                    onChange={handleChange}
                                    disabled={isFormLocked}
                                />
                            </td>
                            <td className="border border-gray-300 p-2">
                                <div className="flex align-middle">
                                    <input
                                        type="file"
                                        name="mainbody_pdf"
                                        onChange={handleChange}
                                        disabled={isFormLocked}
                                    />
                                    <p className="text-sm text-red-600 mt-1">Max limit: 6MB</p>
                                </div>
                            </td>
                        </tr>
                        {/* Add more rows if needed */}
                    </tbody>
                </table>


                {/* Full Paper Table */}
                <table className="w-full border-collapse border border-gray-300 m-5">
                    <thead>
                        <tr className="bg-gray-200">
                            <th className="border border-gray-300 p-2">Full Paper Word Count</th>
                            <th className="border border-gray-300 p-2">Upload Full Paper PDF</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="border border-gray-300 p-2">
                                <input
                                    type="number"
                                    name="full_word_count"
                                    className={inputClass}
                                    placeholder='Full Paper Word Count'
                                    value={formData.full_word_count}
                                    onChange={handleChange}
                                    disabled={isFormLocked}
                                />
                            </td>

                            <td className="border border-gray-300 p-2">
                                <div className="flex align-middle">
                                    <input
                                        type="file"
                                        name="full_paper_pdf"
                                        onChange={handleChange}
                                        disabled={isFormLocked}
                                    />
                                    <p className="text-sm text-red-600 mt-1">Max limit: 12MB</p>
                                </div>
                            </td>
                        </tr>
                        {/* Add more rows if needed */}
                    </tbody>
                </table>

                {/* illustrations table */}
                <table className="w-full border-collapse border border-gray-300 m-5 mb-2">
                    <thead>
                        <tr className="bg-gray-200">
                            <th className="border border-gray-300 p-2">Illustrations Number</th>
                            <th className="border border-gray-300 p-2">Upload PDF</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="border border-gray-300 p-2">
                                <input
                                    type="number"
                                    name="illustrations_number"
                                    value={formData.illustrations_number}
                                    className={inputClass}
                                    placeholder='Illustrations Number'
                                    onChange={handleChange}
                                    disabled={isFormLocked}
                                />
                            </td>

                            <td className="border border-gray-300 p-2">
                                <div className="flex align-middle">
                                    <input
                                        type="file"
                                        name="illustrations_pdf"
                                        onChange={handleChange}
                                        disabled={isFormLocked}
                                    />
                                    <p className="text-sm text-red-600 mt-1">Max limit: 5MB</p>
                                </div>
                            </td>
                        </tr>
                        {/* Add more rows if needed */}
                    </tbody>
                </table>

                {success?.length > 0 &&
                    <FormSubmitSuccess children={success} />
                }

                {fail?.length > 0 &&
                    <FormSubmitError children={fail} />
                }

                {loader &&
                    <DotLoader />
                    }

                <div className="flex justify-center">

                    <button
                        className="bg-blue-500 text-white py-2 px-4 mr-4 disabled:bg-blue-200 rounded"
                        onClick={handleSave}
                        disabled={isFormLocked}
                    >
                        Save
                    </button>
                    <button
                        className="bg-green-500 text-white py-2 px-4 disabled:bg-green-200 rounded"
                        onClick={handleLock}
                        disabled={isFormLocked}
                    >
                        Lock
                    </button>
                </div>
            </div>
        );
    };

    export default PaperSubmissionForm;
