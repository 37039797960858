import React, { useState } from 'react';
import requests from '../../requests';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import FormSubmitError from '../Success/FormSubmitError';
import DotLoader from '../DotLoader';

const Login = () => {

  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const navigate = useNavigate();
  const { _login } = useAuth();
  const [ fail, setFail ] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prevCredentials) => ({ ...prevCredentials, [name]: value }));
  };

  const handleSubmit = async (e) => {

    e.preventDefault();

    setLoader(true);

    try {

      const response = await requests.login(credentials);

       setLoader(false);

      if (response.data.status === 'ok') {
        _login(response.data.user);
        navigate('/');

      } else {

        setFail("Invalid Username or Password");

        setTimeout(() => {
          setFail('')
        }, 4000);

      }
    } catch (error) {

      setFail('Something Went Wrong');
      setTimeout(() => {
        setFail('')
      }, 4000);


    }

  };

  return (
    <div className="flex items-center justify-center">
      <div className="bg-white p-8 rounded shadow-md w-96">
        <h1 className="text-2xl font-semibold mb-6">Login</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="username"
              className="block text-gray-600 text-sm font-medium mb-2"
            >
              Username
            </label>
            <input
              type="text"
              id="username"
              name="username"
              value={credentials.username}
              onChange={handleChange}
              className="w-full border rounded-md py-2 px-3 focus:outline-none focus:ring focus:border-blue-300"
              placeholder="Enter your username"
              required
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="password"
              className="block text-gray-600 text-sm font-medium mb-2"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={credentials.password}
              onChange={handleChange}
              className="w-full border rounded-md py-2 px-3 focus:outline-none focus:ring focus:border-slate-500"
              placeholder="Enter your password"
              required
            />
          </div>
          {loader && <DotLoader />}
          {fail && <FormSubmitError children={fail}/>}
          <button
            type="submit"
            className="w-full bg-slate-700 text-white rounded-md py-2 px-4 hover:bg-slate-600 focus:outline-none focus:ring focus:border-slate-300"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
