import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

axios.defaults.baseURL = BASE_URL;

const requests = {

    login: async (user) => {

        console.log(user);

        try {
            const response = await axios.post('/login', user);
            return response;

        } catch (error) {
            return error.response;
        }
    },

    register: async (user) => {

        console.log(user);

        try {
            const response = await axios.post('/register', user, {

                headers:{
                    "Authorization" : 'Bearer ' + sessionStorage.getItem('jwt-token'),
                    'Content-Type' : 'application/json'
                }
            } );
            
            return response;

        } catch (error) {
            return error.response;
        }
    },
    
    paperUpload: async (data) => {


        try {
            const response = await axios.post('/uploadPaper', data, {

                headers :{
                    "Authorization" : 'Bearer ' + sessionStorage.getItem('jwt-token'),
                    'Content-Type' : 'multipart/form-data'
                }
            });

            console.log(response);
            return response;

        } catch (error) {
            return error.response;
        }
    },

    getPaper: async () => {

        try {
            const response = await axios.get('/getPaperById/', {

                headers :{
                    "Authorization" : 'Bearer ' + sessionStorage.getItem('jwt-token'),
                    'Content-Type' : 'application/json'
                }
            });
            return response;
        } catch (error) {
            return error.response;
        }
    },

    updatePaper: async (data) => {

        try {

            const response = await axios.put('/updatePaper',data, {

                headers :{
                    "Authorization" : 'Bearer ' + sessionStorage.getItem('jwt-token'),
                    'Content-Type' : 'multipart/form-data'
                }
            });

            return response;

        } catch (error) {
            return error.response;
        }
    },

    setLock: async () => {

        try {

            const response = await axios.put('/formLock', null, {

                headers:{
                    "Authorization" : 'Bearer ' + sessionStorage.getItem('jwt-token'),
                }
            });

            return response;

        } catch (error) {
            return error.response;
        }
    },

    getUsers: async () => {

        try {
            const response = await axios.get('/getUsers' ,{

                headers :{
                    "Authorization" : 'Bearer ' + sessionStorage.getItem('jwt-token'),
                }
            });
            return response;
        } catch (error) {
            return error.response;
        }
    },

    getPaperByUsername: async (username) => {

        try {
            const response = await axios.get(`/getPaperByUsername/${username}`, {

                headers :{
                    "Authorization" : 'Bearer ' + sessionStorage.getItem('jwt-token'),
                    'Content-Type' : 'application/json'
                }
            });
            return response;
        } catch (error) {
            return error.response;
        }
    },

    toggleLock: async (username) => {
        
        try {

            const response = await axios.get(`/formLockToggle/${username}`, {

                headers:{
                    "Authorization" : 'Bearer ' + sessionStorage.getItem('jwt-token'),
                    'Content-Type' : 'application/json'
                }
            });

            return response;

        } catch (error) {
            return error.response;
        }
    },


}

export default requests;
