import React from 'react'

function FormSubmitError({ children }) {
    return (

        <>
            <div className="flex justify-center m-4">
            <div className="bg-red-200 text-red-800 px-4 rounded">
                {children}
            </div>
            </div>

        </>
    )
}

export default FormSubmitError