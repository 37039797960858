import React, { useEffect } from 'react'
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import PaperSubmissionForm from './Forms/PaperSubmissionForm';

function Home() {

  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {

    if (!isLoggedIn) {
      navigate('/login');
    }

  }, [isLoggedIn])

  return (
    <>

      <PaperSubmissionForm />


    </>
  )
}

export default Home