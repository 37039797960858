import './App.css';
import Navbar from './components/Navbar';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Login from './components/Forms/login';
import { AuthProvider } from './context/AuthContext';
import Admin from './components/Admin';
import Register from './components/Forms/register';

function App() {


  return (
    <>
      <AuthProvider>
        
        <BrowserRouter>
        <Navbar />
          <Routes>
          
            <Route path="/" element={<Home />}></Route>
            <Route path="/*" component={<Home />} />
            <Route path="/login" element={<Login />}></Route>
            <Route path="/admin" element={<Admin />}></Route>
            <Route path="/registerUser" element={<Register />}></Route>
          </Routes>

        </BrowserRouter>
      </AuthProvider>
    </>
  );
}

export default App;
