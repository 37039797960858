import React, { useEffect } from 'react'
import DropdownMenu from './DropdownMenu'
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import PaperSubmissionAdmin from './Forms/PaperSubmissionAdmin';


function Admin() {

    const navigate = useNavigate();


    useEffect(() => {

        const isAdmin = Cookies.get('ad_data');

        if (!isAdmin) {

            navigate('/');
        }
    }, [navigate])

    return (

        <div className='flex flex-col justify-center mt-5'>
            <div className='flex justify-center gap-5'>

                <DropdownMenu />

                <button
                    className="text-white bg-green-900 hover:bg-green-700 py-2 px-4 rounded "
                    onClick={()=>{ navigate('/registerUser')}}
                >+ Add New User </button>

            </div>

            <PaperSubmissionAdmin />
        </div>
    )
}

export default Admin