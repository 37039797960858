import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import requests from '../requests';
import { useAuth } from '../context/AuthContext';

const DropdownMenu = () => {
    const [data, setData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const { setError, setNewFormData, setSelectedUsername, setMessage, message } = useAuth();

    useEffect(() => {
        const getAllData = async () => {
            try {
                const response = await requests.getUsers();
                setData(response.data);
            } catch (error) {
                console.log(error);
            }
        }

        getAllData();

    }, []);

    const handleSelect = async (selectedOption) => {
        if (selectedOption) {
            setSelectedItem(selectedOption);
            setSelectedUsername(selectedOption.value);
    
            try {
                const response = await requests.getPaperByUsername(selectedOption.value);
    
                if (response.status === 404) {
                    setError(true);
                    setMessage(response.data.message);
                }
    
                const dataArray = response.data;
    
                if (dataArray.length > 0) {
                    setError(false);
                    setMessage(response.data.message);
                    const selectedData = await dataArray[0];
                    setNewFormData(selectedData);
                }
    
            } catch (error) {
                console.log(error);
            }
        } else {
            // Handle the case where no option is selected (box is empty)
            setSelectedItem(null);
            setSelectedUsername(null);
            // You might want to clear other related state variables here
        }
    };
    const formatOptions = data.map(item => ({ value: item, label: item }));

    return (
        <div className='flex flex-col'>
            <Select
                options={formatOptions}
                value={selectedItem}
                onChange={handleSelect}
                placeholder="Select an Username"
                isSearchable
                isClearable
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 })
                }}
            />
            {message && <div className='text-red-700 mt-3'>{message}</div>}
        </div>
    );
};

export default DropdownMenu;
