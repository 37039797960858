import React from 'react'

function FormSubmitSuccess({ children }) {
    return (

        <>
            <div className="flex justify-center mt-4">
            <div className=" bg-green-200 text-green-800 px-4 rounded">
                {children}
            </div>
            </div>

        </>
    )
}

export default FormSubmitSuccess