import React from 'react';
import { useAuth } from '../context/AuthContext';

function FileDownloadSection() {
    const { newFormData, selectedUsername } = useAuth();

    const downloadPdf = (base64Data, filename) => {
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = filename || 'download.pdf';
        a.click();
        URL.revokeObjectURL(url);
    };

    return (
        <>
            <div className='flex justify-center'>
                <p className="text-lg font-semibold text-green-900 underline-offset-2">
                    Download Research Paper By {selectedUsername}
                </p>
            </div>
            <hr />
            {Object.entries(newFormData).map(([key, value], index) => (
                key.endsWith('_pdf') && value.data && (
                    <div key={index} className="flex justify-center align-middle bg-white p-4 rounded-md gap-3">
                        <p className="text-base font-semibold text-gray-800">{value.filename}</p>
                        <button onClick={() => downloadPdf(value.data.toString('base64'), value.filename)} className="inline-block bg-slate-800 text-white py-2 px-4 rounded-md hover:bg-slate-600 focus:outline-none focus:shadow-outline-slate active:bg-slate-900 text-sm">
                            Download
                        </button>
                    </div>
                )
            ))}
        </>
    );
}

export default FileDownloadSection;

