import React, { useEffect, useState } from 'react';
import requests from '../../requests';
import FormSubmitError from '../Success/FormSubmitError';
import FormSubmitSuccess from '../Success/FormSubmitSuccess';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import DotLoader from '../DotLoader';

const Register = () => {

  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [success, setSuccess] = useState('');
    const [fail, setFail] = useState('');
    const [loader, setLoader] = useState(false);

    const navigate = useNavigate();


    useEffect(() => {

        const isAdmin = Cookies.get('ad_data');

        if (!isAdmin) {

            navigate('/');
        }
        
    }, [])



  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prevCredentials) => ({ ...prevCredentials, [name]: value }));
  };

  const handleSubmit = async(e) => {

    e.preventDefault();

    setLoader(true);
    
    try{

        const response = await requests.register(credentials);
        setLoader(false);
        console.log(response.data);

        if (response.data.status === 'ok') { 

            setSuccess(response.data.message)

            setTimeout(() => {
                setSuccess('')
            }, 4000);

        } else {

            setFail(response.data.message)
            setTimeout(() => {
                setFail('')
            }, 4000);
        }

    } catch(error){

        alert(error);

    }

  };

  return (
    <div className="flex items-center justify-center">
      <div className="bg-white p-8 rounded shadow-md w-96">
        <h1 className="text-2xl font-semibold mb-6">Register New User</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="username"
              className="block text-gray-600 text-sm font-medium mb-2"
            >
              Username
            </label>
            <input
              type="text"
              id="username"
              name="username"
              value={credentials.username}
              onChange={handleChange}
              className="w-full border rounded-md py-2 px-3 focus:outline-none focus:ring focus:border-blue-300"
              placeholder="Enter your username"
              required
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="password"
              className="block text-gray-600 text-sm font-medium mb-2"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={credentials.password}
              onChange={handleChange}
              className="w-full border rounded-md py-2 px-3 focus:outline-none focus:ring focus:border-slate-500"
              placeholder="Enter your password"
              required
            />
          </div>

          {success?.length > 0 &&
                <FormSubmitSuccess children={success} />
            }

            {fail?.length > 0 &&
                <FormSubmitError children={fail} />
            }
            { loader && <DotLoader /> }
          <button
            type="submit"
            className="w-full bg-slate-700 text-white rounded-md py-2 px-4 hover:bg-slate-600 focus:outline-none focus:ring focus:border-slate-300"
          >
            Register
          </button>
        </form>
      </div>
    </div>
  );
};

export default Register;
