import { useAuth } from '../../context/AuthContext';
import requests from '../../requests';
import FileDownloadSection from '../FileDownloadSection';
import FormSubmitError from '../Success/FormSubmitError';
import FormSubmitSuccess from '../Success/FormSubmitSuccess';
import './style.css';

import React, { useEffect, useState } from 'react';

const PaperSubmissionAdmin = () => {

    const { newFormData, selectedUsername, error } = useAuth();

    const [success, setSuccess] = useState('');
    const [fail, setFail] = useState('');
    const [isFormLocked, setIsFormLocked] = useState(true);
    const [buttonToggle, setButtonToggle] = useState(newFormData.isLocked);
    

    useEffect(() => {
        // Update isFormLocked when newFormData.isLocked changes
        setButtonToggle(newFormData.isLocked);

    
    }, [newFormData.isLocked]);

    const handleLock = async () => {

        try {

            const response = await requests.toggleLock(selectedUsername);


            if (response.data.isLocked) {


                setFail("Account has been locked")

                setTimeout(() => {
                    setFail('')
                }, 4000);

            } else if (!response.data.isLocked) {

                setSuccess("Account has been unlocked")


                setTimeout(() => {
                    setSuccess('')
                }, 4000);
            }

            setButtonToggle(response.data.isLocked);

        } catch (error) {

            console.log(error);
        }


    };

    const inputClass = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500";

    return (
        <>

           { !error && 

                <div className="container mx-auto mt-8">

                    <div className='flex justify-center'>

                        {selectedUsername && <div className='flex gap-2 justify-center'>Username Selected: <p className='text-green-700 font-serif text-xl'>{selectedUsername}</p></div>}

                    </div>
                    {/* Paper Details Table */}
                    <table className="w-full border-collapse border border-gray-300 m-5">
                        <thead>
                            <tr className="bg-gray-200">
                                <th className="border border-gray-300 p-2">Paper Title</th>
                                <th className="border border-gray-300 p-2">First Author</th>
                                <th className="border border-gray-300 p-2">Corresponding Author</th>
                                <th className="border border-gray-300 p-2">Third Author</th>
                                <th className="border border-gray-300 p-2">Fourth Author</th>
                                <th className="border border-gray-300 p-2">Institute Affiliation</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="border border-gray-300 p-2">
                                    <input
                                        type="text"
                                        name="paper_title"
                                        value={newFormData.paper_title}
                                        className={inputClass}
                                        placeholder='Paper Title'
                                        required

                                        disabled={isFormLocked}
                                    />
                                </td>
                                <td className="border border-gray-300 p-2">
                                    <input
                                        type="text"
                                        name="first_author"
                                        value={newFormData.first_author}
                                        className={inputClass}
                                        placeholder='First Author'
                                        required

                                        disabled={isFormLocked}
                                    />
                                </td>
                                <td className="border border-gray-300 p-2">
                                    <input
                                        type="text"
                                        name="corresponding_author"
                                        value={newFormData.corresponding_author}
                                        className={inputClass}
                                        placeholder='Corresponding Author'
                                        required

                                        disabled={isFormLocked}
                                    />
                                </td>
                                <td className="border border-gray-300 p-2">
                                    <input
                                        type="text"
                                        name="third_author"
                                        value={newFormData.third_author}
                                        className={inputClass}
                                        placeholder='Third Author'
                                        required

                                        disabled={isFormLocked}
                                    />
                                </td>
                                <td className="border border-gray-300 p-2">
                                    <input
                                        type="text"
                                        name="fourth_author"
                                        value={newFormData.fourth_author}
                                        className={inputClass}
                                        placeholder='Fourth Author'
                                        required

                                        disabled={isFormLocked}
                                    />
                                </td>
                                <td className="border border-gray-300 p-2">
                                    <input
                                        type="text"
                                        name="institute_affiliation"
                                        value={newFormData.institute_affiliation}
                                        className={inputClass}
                                        placeholder='Institute Affiliation'
                                        required

                                        disabled={isFormLocked}
                                    />
                                </td>
                            </tr>

                        </tbody>

                    </table>

                    {/* Abstract Table */}
                    <table className="w-full border-collapse border border-gray-300 m-5">
                        <thead>
                            <tr className="bg-gray-200">
                                <th className="border border-gray-300 p-2">Abstract Word Count</th>
                                <th className="border border-gray-300 p-2">Keywords</th>
                                <th className="border border-gray-300 p-2">Upload Abstract PDF</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="border border-gray-300 p-2">
                                    <input
                                        type="number"
                                        name="abstract_word_count"
                                        value={newFormData.abstract_word_count}
                                        className={inputClass}
                                        placeholder='Abstract Word Count'
                                        required

                                        disabled={isFormLocked}
                                    />
                                </td>
                                <td className="border border-gray-300 p-2">
                                    <input
                                        type="text"
                                        name="keywords"
                                        value={newFormData.keywords}
                                        className={inputClass}
                                        placeholder='Keywords'
                                        required

                                        disabled={isFormLocked}
                                    />
                                </td>
                                <td className="border border-gray-300 p-2">
                                    <div className="">
                                        <input
                                            type="file"
                                            name="abstract_pdf"
                                            required

                                            disabled={isFormLocked}
                                        />
                                    </div>
                                </td>
                            </tr>
                            {/* Add more rows if needed */}
                        </tbody>
                    </table>

                    {/* Main Body Table */}
                    <table className="w-full border-collapse border border-gray-300 m-5">
                        <thead>
                            <tr className="bg-gray-200">
                                <th className="border border-gray-300 p-2">Main Body Word Count</th>
                                <th className="border border-gray-300 p-2">No of Figures</th>
                                <th className="border border-gray-300 p-2">Upload Abstract PDF</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="border border-gray-300 p-2">
                                    <input
                                        type="number"
                                        name="main_word_count"
                                        value={newFormData.main_word_count}
                                        className={inputClass}
                                        placeholder='Main Body Word Count'
                                        required

                                        disabled={isFormLocked}
                                    />
                                </td>
                                <td className="border border-gray-300 p-2">
                                    <input
                                        type="text"
                                        name="no_of_figures"
                                        value={newFormData.no_of_figures}
                                        className={inputClass}
                                        placeholder='No of Figures'
                                        required

                                        disabled={isFormLocked}
                                    />
                                </td>
                                <td className="border border-gray-300 p-2">
                                    <div className="">
                                        <input
                                            type="file"
                                            name="mainbody_pdf"
                                            required

                                            disabled={isFormLocked}
                                        />
                                    </div>
                                </td>
                            </tr>
                            {/* Add more rows if needed */}
                        </tbody>
                    </table>


                    {/* Full Paper Table */}
                    <table className="w-full border-collapse border border-gray-300 m-5">
                        <thead>
                            <tr className="bg-gray-200">
                                <th className="border border-gray-300 p-2">Full Paper Word Count</th>
                                <th className="border border-gray-300 p-2">Upload Full Paper PDF</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="border border-gray-300 p-2">
                                    <input
                                        type="number"
                                        name="full_word_count"
                                        className={inputClass}
                                        placeholder='Full Paper Word Count'
                                        required
                                        value={newFormData.full_word_count}

                                        disabled={isFormLocked}
                                    />
                                </td>

                                <td className="border border-gray-300 p-2">
                                    <div className="">
                                        <input
                                            type="file"
                                            name="full_paper_pdf"
                                            required

                                            disabled={isFormLocked}
                                        />
                                    </div>
                                </td>
                            </tr>
                            {/* Add more rows if needed */}
                        </tbody>
                    </table>

                    {/* illustrations table */}
                    <table className="w-full border-collapse border border-gray-300 m-5">
                        <thead>
                            <tr className="bg-gray-200">
                                <th className="border border-gray-300 p-2">Illustrations Number</th>
                                <th className="border border-gray-300 p-2">Upload PDF</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="border border-gray-300 p-2">
                                    <input
                                        type="number"
                                        name="illustrations_number"
                                        value={newFormData.illustrations_number}
                                        className={inputClass}
                                        placeholder='Illustrations Number'
                                        required

                                        disabled={isFormLocked}
                                    />
                                </td>

                                <td className="border border-gray-300 p-2">
                                    <div className="">
                                        <input
                                            type="file"
                                            name="illustrations_pdf"
                                            required

                                            disabled={isFormLocked}
                                        />
                                    </div>
                                </td>
                            </tr>
                            {/* Add more rows if needed */}
                        </tbody>
                    </table>

                    <FileDownloadSection />


                    {success?.length > 0 &&
                        <FormSubmitSuccess children={success} />
                    }

                    {fail?.length > 0 &&
                        <FormSubmitError children={fail} />
                    }

                    <hr />
                    
                    <div className="mt-4 flex justify-center">
                      
                        <button
                            className="bg-green-500 text-white py-2 px-4 disabled:bg-green-200 rounded mb-2"
                            onClick={handleLock}
                        >

                            {buttonToggle ? 'Unlock' : 'Lock'}

                        </button>

                    </div>

                </div>
       } 

        </>
    );
};

export default PaperSubmissionAdmin;
