import React from 'react'
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';


const Navbar = () => {

  const { _logout, isLoggedIn, username } = useAuth();
  const navigate = useNavigate();

  const isAdmin = Cookies.get('ad_data');

  const handleLogout = () => {

    if (isLoggedIn) {

      _logout();

      navigate('/login');

    } else {

      navigate('/login')
    }
  }

  const handleAdmin = () => {

    navigate('/admin')
  }

  return (

    <div className='nav-bg-cus'>
      <nav className="flex items-center justify-center gap-72 m-5 mt-0 nav-bg-cus">
      
      {isLoggedIn && 
        <div className="text-white text-xl mt-5">
          Welcome, {username}
        </div> }

        <div className='app-title mt-4'>
            Research and Innovation Portal
        </div>

        {isAdmin &&
          <button
            className="text-white bg-slate-400 hover:bg-slate-700 py-2 px-4 rounded mt-5"
            onClick={handleAdmin}
          > Admin Panel </button>
        }

        {isLoggedIn &&  
          <button
            className="text-white text-xm bg-slate-400 hover:bg-slate-700 py-2 px-4 rounded mt-5"
            onClick={handleLogout}
          >
            Logout
          </button>
        }
      </nav>

      <hr className='h-px my-1 bg-gray-200 border-0 dark:bg-gray-700' />
    </div>
  );
};

export default Navbar